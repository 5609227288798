html {
    min-height: 100vh;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    color: #333333;
}

html::after {
    content: '';
    display: block;
    position: absolute;
    background: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

html::before {
    content: '';
    display: block;
    position: absolute;
    // background: url('../assets/bg-blur.jpg') center center/cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
}

body,
button,
input,
select,
textarea {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    margin: 0;
}

code {
    font-family: 'Open Sans', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}
