// light
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300&display=swap&subset=cyrillic');
// regular
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap&subset=cyrillic');
//bold
@import url('https://fonts.googleapis.com/css?family=Open+Sans:700&display=swap&subset=cyrillic');
// icons
@font-face {
    font-family: 'Icons';
    src: url('../assets/fonts/fontello.ttf'), url('../assets/fonts/icons.eot');
}
// @todo !!! - remove unnecesery icons and files
